/*=============================================
=            categories             =
=============================================*/


.categories-page {
    display: flex;
    flex-direction: column;
    align-self: start;
    width: 100%;

    &__title {
        color: $black--seven;
    }
};

.list-categories {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    width: 100%;
    padding: 15px;
    user-select: none;
}


.list-category {
    background-color: $grey--three;
    box-shadow: 0 4px 6px rgba($black, 0.1);

    width: 100%;
    cursor: pointer;

    display: grid;
    grid-template-rows: 150px auto;

    &__image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &__title {
        color: $black--seven;
        font-size: 1.4em;
        padding-top: 15px;
        padding-left: 15px;
        text-align: left;
    }
    &__sub-title {
        color: $grey--four;
        font-size: 1em;
        padding-top: 5px;
        padding-left: 15px;
        padding-bottom: 15px;
        text-align: left;
    }
    &__content {
        .title {
            font-size: 16px;
            line-height: 1.3;
        }
        .category {
            font-size: 12px;
            line-height: 1;

            display: inline-block;

            margin-bottom: 7px;

            color: $grey--four;
        }

        .price {
            font-size: 16px;
            font-weight: 500;
            line-height: 1;

            color: $theme-color--default;

            .main-price {
                text-decoration: line-through;
            }
        }
    }
}

/*=====  End of categories   ======*/
