
/*=============================================
=            breadcrumb            =
=============================================*/

.breadcrumb-area {

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    .page-title {
        font-size: 14px;
        grid-column: 1;
        grid-row: 1;
        align-self: center;
        justify-self: center;
    }
    .back-link {
        font-weight: 400;
        font-size: 0.9em;
        grid-column: 1;
        grid-row: 1;
        align-self: center;
        justify-self: start;
        padding-left: 10px;

        color: $black;
        i {
            margin-right: 3px;
        }
    }

    .text-align-left {
        text-align: left;
    }

    .cart {
        grid-column: 1;
        grid-row: 1;
        align-self: center;
        justify-self: end;
        padding-right: 10px;
    }
}


/*=====  End of breadcrumb  ======*/

