/*=============================================
=            Helper            =
=============================================*/

@for $i from 0 through 15 {
    .row-#{1 * $i} {
        margin-right: -1px * $i;
        margin-left: -1px * $i;

        & > [class*="col"] {
            padding-right: 1px * $i;
            padding-left: 1px * $i;
        }
    }
}
@for $i from 16 through 50 {
    .row-#{1 * $i} {
        margin-right: -1px * $i;
        margin-left: -1px * $i;

        // Responsive
        @media #{$large-desktop} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$small-desktop} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$extra-large-mobile} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$large-mobile} {
            margin-right: -15px;
            margin-left: -15px;
        }

        & > [class*="col"] {
            padding-right: 1px * $i;
            padding-left: 1px * $i;

            // Responsive
            @media #{$large-desktop} {
                padding-right: 15px;
                padding-left: 15px;
            }
            @media #{$small-desktop} {
                padding-right: 15px;
                padding-left: 15px;
            }
            @media #{$extra-large-mobile} {
                padding-right: 15px;
                padding-left: 15px;
            }
            @media #{$large-mobile} {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
    & > .col,
    & > [class*="col-"] {
        margin: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/*=====  End of Helper  ======*/

