/* contact content */

.contact-page-content {
    &__image {
        height: 200px;
    }
    &__text {
        h3 {
            font-size: 18px;

            margin-bottom: 10px;
        }
    }

    &__text-block {
        h4 {
            font-size: 14px;

            margin-bottom: 5px;
        }
    }
}

.map-wrapper {
    iframe {
        width: 100%;
        height: 300px;

        border: none;
    }
}