/*=============================================
=            Header            =
=============================================*/
header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;

    width: 100%;

    background-color: $white;
    .header-search {
        max-width: 230px;
        form {
            position: relative;
            input {
                font-size: 14px;

                width: 100%;
                padding: 7px 30px 7px 40px;

                color: #666666;
                border: none;
                border-radius: 25px;
                background-color: #EFEFEF;
            }
            svg,
            img {
                position: absolute;
                top: 50%;
                left: 15px;

                transform: translateY(-50%);
            }
        }
    }

    .header-menu-trigger {
        padding: 0;

        border: none;
        background: none;
        &:focus {
            outline: none;
        }
    }
}

/* offcanvas menu */

.profile-card {
    &__image {
        img {
            border-radius: 50%;
        }
    }
    &__content {
        p {
            font-weight: 500;

            color: $black--four;
            span {
                font-size: 12px;
                font-weight: 400;

                display: block;
            }
        }
    }
}

.offcanvas-menu {
    position: fixed;
    z-index: 9999;
    top: 70px;
    right: 0;

    visibility: hidden;

    width: 280px;
    max-width: 100%;
    height: 100vh;
    margin-left: auto;
    padding: 40px;

    transition: $transition--medium;
    transform: translateX(100%);

    background-color: #F1F1F1;
    background-image: linear-gradient(to bottom, #F1F1F1 0%, #F2F8FF 100%);
    box-shadow: -5px 0 15px rgba($black, 0.16);

    &.active {
        visibility: visible;

        transform: translateX(0);
    }
}

.offcanvas-navigation {
    overflow-y: scroll;

    height: 400px;
    margin: 0 -40px;
    padding: 0 40px;

    li {
        display: flex;
        align-items: center;

        padding: 15px 10px 15px 30px;

        border-bottom: 2px solid $white;

        &:nth-child(1) {
            border-top: 2px solid $white;
        }

        span {
            display: block;

            margin-right: 15px;

            svg,
            img {
                width: 14px;
                height: 14px;
            }
        }

        a {
            display: block;

            width: 100%;

            color: $black--four;
        }
    }
}

/* search keyword */

.search-keyword-area {
    display: none;
    &.active {
        display: block;
    }
}

.search-keyword-header {
    line-height: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
        font-size: 14px;

        flex-basis: 80%;
    }

    &__gl-switcher {
        flex-basis: 20%;
        button {
            padding: 0;

            border: none;
            background: none;
        }
    }
}

.search-keywords {
    margin-bottom: -10px;
    li {
        display: inline-block;

        margin-right: 10px;
        margin-bottom: 10px;
        a {
            font-size: 13px;
            line-height: 1.3;

            display: block;

            padding: 5px 15px;

            color: $black--five;
            border: 1px solid $grey--five;
            background-color: $white;
        }
    }
}

.center-logo {
    margin: 0 auto;
}

/*=====  End of Header  ======*/
