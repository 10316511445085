/*=============================================
=            profile            =
=============================================*/

/* profile header */

.profile-header {
    display: flex;
    &__image {
        flex-basis: 120px;

        margin-right: 15px;
        img {
            border-radius: 15px;
        }
    }
    &__content {
        flex-basis: calc(100% - 135px);
        .name {
            font-size: 16px;
            font-weight: 700;
        }
    }
    .profile-info {
        display: flex;

        width: 200px;
        max-width: 100%;
        &-block {
            flex-basis: 50%;
            &__value {
                font-size: 12px;
                font-weight: 700;

                color: $black--four;
            }
            &__title {
                font-size: 10px;
                line-height: 1;
            }
        }
    }

    .profile-level {
        &__title {
            font-size: 12px;
            font-weight: 500;

            color: $black--four;
        }

        &__progress {
            height: 4px;

            background-color: rgba($grey--nine, 0.10);
            .progress-bar {
                background-color: $orange--two;
            }
        }
    }
}


/* profile body */

.profile-info-table {
    border-radius: 10px;
    background-color: $white;
    .profile-info-block {
        display: flex;
        align-items: center;

        padding: 15px 30px;

        color: $black--four;
        border-bottom: 2px solid $grey--seven;

        &:last-child {
            border-bottom: none;
        }
        &__title {
            font-size: 14px;
            font-weight: 500;

            flex-basis: 50%;
        }
        &__value {
            font-size: 12px;
            font-weight: 300;

            flex-basis: 50%;

            text-align: right;
            a {
                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}

.edit-profile-form {
    padding: 40px 20px;

    background-color: $white;
    &__single-field {
        label {
            font-size: 14px;

            margin-bottom: 20px;

            color: $black--two;
        }
        input,
        textarea {
            font-size: 18px;

            display: block;

            width: 100%;
            padding-bottom: 10px;

            color: $black--two;
            border: none;
            border-bottom: 2px solid $grey--eight;

            &:focus {
                border-bottom: 2px solid $theme-color--default;
            }
        }

        ::placeholder {
            opacity: 1; /* Firefox */

/* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $grey--four;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $grey--four;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $grey--four;
        }
    }

    &__button {
        font-size: 16px;
        line-height: 1;

        width: 100%;
        padding: 15px 0;

        color: $white;
        border: none;
        background: none;
        background-color: $theme-color--default;
    }
}


/*=====  End of profile  ======*/